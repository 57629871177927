import { render, staticRenderFns } from "./CallView.vue?vue&type=template&id=2674593c&scoped=true&xmlns%3Av-slot=http%3A%2F%2Fwww.w3.org%2F1999%2FXSL%2FTransform&"
import script from "./CallView.vue?vue&type=script&lang=js&"
export * from "./CallView.vue?vue&type=script&lang=js&"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./CallView.vue?vue&type=style&index=1&id=2674593c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2674593c",
  null
  
)

export default component.exports