const IntegrationStatusEnum = Object.freeze({
    CONNECTED: "Connected",
    NOT_CONNECTED: "Not Connected"
})

const CalendarTypeEnum = Object.freeze({
    GOOGLE: 'google'
})

const Calendar_Items = {
    GOOGLE: {
        name: "Google Calendar",
        key: CalendarTypeEnum.GOOGLE,
        icon: "google-calendar"
    }
};

export { IntegrationStatusEnum, Calendar_Items, CalendarTypeEnum }